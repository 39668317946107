<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0">
      <v-data-table
        :headers="head_geolocalizados"
        :items="datos"
        :loading="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        disable-sort
        dense
        no-data-text="No hay registros disponibles dentro de la Fechas seleccionadas."
        mobile-breakpoint="50"
        :items-per-page="8"
        class="data-report hide-footer-select"
      >
        <template v-slot:item.ven_des="{item}">
          <span class="font-weight-medium primary--text">{{item.ven_des}} <strong>({{item.co_ven}})</strong></span>
        </template>
        <template v-slot:item.clientes_geolocalizados="{item}">
         <v-chip
            class="ma-2 font-weight-bold"
            color="success-alert"
            small
            label
            pill
            dark
          >
            <v-icon small left>
              mdi-check
            </v-icon>
            {{ item.clientes_geolocalizados }}
          </v-chip>
        </template>
        <template v-slot:item.total_clientes="{item}">
          <div class="d-flex justify-center">
            <span class="blue-grey--text font-weight-black" v-text="item.total_clientes"></span>
          </div>
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DataGeolocalizados',
  props:{
    datos: {
      type: Array,
      default: () => ([]),
    },
    loading:{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    head_geolocalizados:[
      { text: 'Vendedor', value: 'ven_des', class: 'header-data' },
      { text: 'Geolocalizados', value: 'clientes_geolocalizados', class: 'header-data', align: 'center'},
      { text: 'Total', value: 'total_clientes', class: 'header-data', align: 'center'},
    ]
  }),
}
</script>

<style lang="sass">
.data-report tbody tr:nth-of-type(odd)
  background-color: #FAFAFA
  border-color: #ECEFF1 !important

.hide-footer-select .v-data-footer__select
  display: none !important
</style>
